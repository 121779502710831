export const dashboardNavigations = [
  {
    name: "DASHBOARD",
    description: "Lorem ipsum dolor sit.",
    type: "link",
    icon: "i-Bar-Chart",
    path: "/dashboard",
  },
  {
    name: "APPLICATIONS",
    description: "Lorem ipsum dolor sit.",
    type: "dropDown",
    icon: "i-Library",
    sub: [
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "FINAL LIST",
        path: "/applications/final-applications ",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "SHORT LIST",
        path: "/applications/approved-applications",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "NEW",
        path: "/applications/new-applications",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "UNDER REVIEW",
        path: "/applications/under-review-applications",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "REJECTED",
        path: "/applications/rejected-applications",
        type: "link",
      },
    ],
  },
  {
    icon: "i-File-Horizontal-Text",
    name: "REPORTS",
    type: "dropDown",
    sub: [
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "NOT SUBMITTED",
        path: "/applicant/report/not-submitted",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "SIGN UP",
        path: "/applicant/report/signup",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "Male Applicant",
        path: "/applicant/report/male",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "Female Applicant",
        path: "/applicant/report/female",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "Punjab Applicant",
        path: "/applicant/report/punjab",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "Sindh Applicant",
        path: "/applicant/report/sindh",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "KPK Applicant",
        path: "/applicant/report/kpk",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "Balochistan Applicant",
        path: "/applicant/report/balochistan",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "Islamabad Applicant",
        path: "/applicant/report/isb",
        type: "link",
      },
      {
        icon: "i-File-Clipboard-Text--Image",
        name: "Gilgit Baltistan Applicant",
        path: "/applicant/report/gb",
        type: "link",
      },{
        icon: "i-File-Clipboard-Text--Image",
        name: "Azad Kashmir Applicant",
        path: "/applicant/report/ak",
        type: "link",
      },

    ],
  },
];
