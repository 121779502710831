import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import axios from 'axios';
// import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";
import fi from "date-fns/esm/locale/fi/index.js";

const FundingOptions = () => {
    const [content, setContent] = useState(false);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const applicantID = auth ? auth.id : null;


    const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage


    useEffect(() => {
     const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
     if (disabledStatuses.includes(status)) {
       setIsDisabled(true);
     } else {
       setIsDisabled(false);
     }
   }, [status]); // Re-evaluate when the status changes

    const handleContentChange = () => {
        // const content = event.target.value;

        setContent(prevChecked => prevChecked === 0 ? 1 : 0);

    };

    const handleSubmit = async (event) => { // Declare the function as async
        const reference_letter = localStorage.getItem('Refrence_letter');
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

        event.preventDefault();
        console.log("content", content);
    
        // if (content === false) {
        //     NotificationManager.error("Content field is required");
        //     return;
        // }
    
        try {
            let data
            if(content == 1)
            {
             data = {
                scholarship: false
            }
        }
            else{
                 data = {
                    scholarship: true
                }
            }
            
    
            // Only hit the second API (Fetch PUT request)
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/reference-letters/${reference_letter}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: data }) // Send the data wrapped in a 'data' object

            });
    
            const responseData = await response.json(); // Use a different variable name for the response
    
            if (response.ok) {
                console.log("Success:", responseData);
                // Optionally, navigate to another page if required
                navigate("/application/privacy-policy-statement");
            } else {
                console.error(`Error: ${response.statusText}`);
            }
    
        } catch (error) {
            console.error(`Error in API call: ${error}`);
        }
    };
    

    return (
        <div>
            {/* Header Start */}
            <div className="mb-5" >

                <DashboardHeader />
            </div>

            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>
                        <div className="col-sm-12 col-md-8 mb-4">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                                </div>

                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="card ">
                                        <div className="card-body">
                                            <h4>Funding Options</h4>
                                            <p className="w-100">
                                                Please note that this training program covers the costs of accommodation and food during the two-week training in Islamabad. However, participants are responsible for making their own travel arrangements to and from the training location.
                                            </p>
                                            <p className="w-100">
                                                For those seeking financial aid to cover travel expenses, a limited number of scholarships are available to eligible applicants. Scholarship recipients will be chosen based on a competitive selection process that considers both financial need and merit.
                                            </p>
                                            <div className="ul-widget-app__task-list my-4">
                                                <label className="checkbox checkbox-primary">
                                                    <input type="checkbox" checked={content === 1} onChange={handleContentChange}  disabled={isDisabled}/>
                                                    <strong>
                                                        Select the checkbox below if you wish to apply for this scholarship. Applying for the scholarship will not affect your selection process.
                                                    </strong>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="mc-footer">
                                                <div className="row text-center">
                                                    <div className="col-lg-12 ">

                                                        <button type="submit" className="btn btn-primary text-white m-1">
                                                            Save & Next
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary m-1"
                                                            onClick={() => navigate('/application/privacy-policy-statement')}
                                                        >
                                                            Skip
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <NotificationContainer /> */}
        </div>
    );
};

export default FundingOptions;
