import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from './DashboardHeader';
import { NotificationContainer, NotificationManager } from "react-notifications";

const Resume = () => {
    const [files, setFiles] = useState({});
    const [inputKey, setInputKey] = useState(Date.now());
    const [resumeData, setResumeData] = useState({});
    const [resumeId, setResumeId] = useState({});

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const token = localStorage.getItem('token');
    const applicationId = parseInt(localStorage.getItem('id'), 10);
    const status = localStorage.getItem('application_status');

    const requiredFields = [
        'photograph', 
        'challan', 
        'electricity_bill', 
        'nic_card', 
        'parent_cnic', 
        'result_card'
    ];

    useEffect(() => {
        const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
        setIsDisabled(disabledStatuses.includes(status));
    }, [status]);

    useEffect(() => {
        const fetchResumeData = async () => {
            try {
                const campaignResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const campaignData = await campaignResponse.json();
                const latestCampaign = campaignData.data[0];

                if (!latestCampaign) {
                    console.error("No campaign found");
                    return;
                }

                const resumeResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/resumes?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}&populate=*`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                const resumeResult = await resumeResponse.json();
                const resume = resumeResult.data[0];
                setResumeId(resume.id)
                   console.log("resume",resume)
                if (resume) {
                    const existingFiles = {};
                    requiredFields.forEach(field => {
                        if (resume.attributes[field]?.data?.attributes) {
                            existingFiles[field] = {
                                file: null,  // No new file selected yet
                                path: resume.attributes[field].data.attributes.url,
                                name: resume.attributes[field].data.attributes.name,
                            };
                        }
                    });

                    setResumeData(existingFiles);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching resume data:', error);
            }
        };

        fetchResumeData();
    }, [applicationId, token]);

    const handleFileChange = (e, field) => {
        if (e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            if (selectedFile.size <= 5000000 && selectedFile.type === 'application/pdf') {
                setResumeData(prev => ({
                    ...prev,
                    [field]: { ...prev[field], file: selectedFile },  // Store new file
                }));
            } else {
                NotificationManager.error("File must be a PDF and less than 5MB");
                setInputKey(Date.now());  // Reset input field
            }
        } else {
            NotificationManager.error('No file selected');
        }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setErrorMessage('');
  
      try {
          const formData = new FormData();
  
          // Helper function to fetch existing files as Blob and add them to FormData
          const fetchAndAppendExistingFiles = async () => {
              for (const field of requiredFields) {
                  const fileData = resumeData[field];
  
                  if (fileData?.file) {
                      // If a new file is selected, attach it
                      formData.append(field, fileData.file);
                  } else if (fileData?.path) {
                      // Fetch the existing file from the URL and append it as a Blob
                      const response = await fetch(fileData.path);
                      const blob = await response.blob();
                      const file = new File([blob], fileData.name, { type: blob.type });
                      formData.append(field, file);
                  }
              }
          };
  
          // Ensure all required files are accounted for
          const missingFiles = requiredFields.filter(
              field => !resumeData[field]?.file && !resumeData[field]?.path
          );
  
          if (missingFiles.length > 0) {
              setErrorMessage(`Please upload: ${missingFiles.join(', ')}`);
              return;
          }
  
          // Fetch and append all files (both new and existing)
          await fetchAndAppendExistingFiles();
  
          // Add application ID to the form data
          formData.append('application_id', applicationId);
       console.log("resumeId",resumeId)
          // Step 1: Delete the previous resume if it exists
          if (resumeId) {
              const deleteResponse = await fetch(
                  `${process.env.REACT_APP_BASE_URL_strapi}/resumes/${resumeId}`,
                  {
                      method: 'DELETE',
                      headers: { 'Authorization': `Bearer ${token}` },
                  }
              );
  
              if (!deleteResponse.ok) {
                  throw new Error('Failed to delete existing resume');
              }
          }
  
          // Step 2: Create a new resume entry with the form data
          const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/resumes`, {
              method: 'POST',
              headers: { 'Authorization': `Bearer ${token}` },
              body: formData,
          });
  
          if (!response.ok) {
              throw new Error('Failed to upload files');
          }
  
          NotificationManager.success('Files uploaded successfully!');
          let outgoingData = {
            status:'new'
          }
            // 2. Second API Call: Update Application Status
    const response2 = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${applicationId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: outgoingData }), // Wrap outgoingData in 'data' object
    });
  
    if (!response2.ok) {
      throw new Error('Failed to update application status');
    }
  
    const data2Response = await response2.json();
    console.log('Application Status Update Response:', data2Response);
    localStorage.setItem("application_status", "new");

    // Navigate to status page after both requests succeed
    navigate('/application/status');
      } catch (error) {
          console.error('Error uploading files:', error);
          NotificationManager.error('Error uploading files. Please try again.');
      }
  };
  
  

    return (
        <div>
            <DashboardHeader />
            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <Navbar />
                        </div>

                        <div className="col-md-8">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status"></div>
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Upload Required Documents</h4>
                                            {requiredFields.map(field => (
                                                <div key={field} className="mb-3">
                                                    <label htmlFor={field} className="form-label">
                                                        {field.replace('_', ' ').toUpperCase()}
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id={field}
                                                        onChange={(e) => handleFileChange(e, field)}
                                                        accept=".pdf"
                                                        disabled={isDisabled}
                                                        key={inputKey + field}
                                                    />
                                                    {resumeData[field]?.path && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary mt-2"
                                                            onClick={() => window.open(resumeData[field].path, '_blank')}
                                                        >
                                                            View Uploaded {field.replace('_', ' ')}
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            {errorMessage && <p className="text-danger">{errorMessage}</p>}
                                        </div>

                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary" disabled={isDisabled}>
                                                Save & Next
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default Resume;
