import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";


const GreenEnergyCommitment = () => {
  const [/*commitmentData*/, setCommitmentData] = useState({});
  
  const [skills, setSkills] = useState("");
  const [content, setContent] = useState("");
  const [referalData, setReferalData] = useState(null);
  const [referalId, setReferalId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [personalStatement, setPersonalStatement] = useState(false);
  const [personalStatementId, setPersonalStatementId] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
 console.log("token",token)
 const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer
 const status = localStorage.getItem('application_status'); // Assuming the token is stored in localStorage


 useEffect(() => {
  const disabledStatuses = ['approved', 'new', 'final_stage', 'rejected'];
  if (disabledStatuses.includes(status)) {
    setIsDisabled(true);
  } else {
    setIsDisabled(false);
  }
}, [status]); // Re-evaluate when the status changes


  useEffect(() => {

    // All Info

      // Fetch the latest campaign first
  fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}` // JWT token of the logged-in user
    }
  })
  .then(response => response.json())
  .then(campaignResponse => {
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign
    setLoading(false)

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // If no campaign exists, handle appropriately
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch personal statements related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(personal_statements => {
        console.log('User with personal statements:', personal_statements);

        const personalStatements = personal_statements.data; // Extract personal statements

        if (personalStatements.length > 0) {
          console.log('Personal Statement related to the user:', personalStatements[0]);

          setContent(personalStatements[0].attributes.personal_statement || "");
          setSkills(personalStatements[0].attributes.skills || "");
          setReferalId(personalStatements[0].attributes.social_media || 0);
          setPersonalStatement(true);
          setPersonalStatementId(personalStatements[0].id);
        } else {
          console.log('No personal statements related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user personal statements:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });

  }, []);


  // word coount
  const handleInputChange = (e) => {
    const wordCount = countWords(e.target.value);
    if (wordCount <= 800) {
      setContent(e.target.value);
      setError(null); // Clear the error
    } else {
      setError('Word count exceeds 800');
    }
  };

  const handlePaste = (e) => {
    // Get pasted data
    const pastedData = e.clipboardData.getData('text');

    // Calculate word count of current content and pasted data
    const wordCount = countWords(content + pastedData);

    // Check if word count exceeds limit
    if (wordCount > 800) {
      // console.error('Error: Word count exceeds 800');
      setError('Word count exceeds 800'); // Set the error
      e.preventDefault(); // Prevent the paste operation
    }
  };

  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
 // Clear any previous error messages
 setErrorMessage('');

 // Validate required fields
 if (!content || !referalId || !skills) {
   setErrorMessage('Please fill out all required fields.');
   return;
 }

    const postData = {
      personal_statement: content,
      social_media: referalId ,
      skills: skills
    };
     console.log(postData);
     if(personalStatement){
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements/${personalStatementId}`, {
          method: 'put', 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure content-type is set to JSON
          },
          body: JSON.stringify({ data: postData }) // Send the data wrapped in a 'data' object
        });
    
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
    
        const data = await response.json();
        console.log(data);
        navigate("/application/reference-letter");
      } catch (error) {
        console.error('Error:', error);
      }
    }else{
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/personal-statements`, {
        method: 'POST', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', // Ensure content-type is set to JSON
        },
        body: JSON.stringify({ data: {...postData ,applicationId},}) // Send the data wrapped in a 'data' object
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
  
      const data = await response.json();
      console.log(data);
      localStorage.setItem("personal_statement", data.id);

      navigate("/application/reference-letter");
    } catch (error) {
      console.error('Error:', error);
    }
  }
  };

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5" >

        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}

                  <div className="card ">
                    <div className="card-body">
                      <h4 >
                        Personal Satement
                      </h4>
                      <p style={{ width: "100%" }}>
                        A personal statement is required from all applicants. Although there is no fixed format for your personal statement, below are some prompts to help you get started. Your statement must be written in English and should not exceed 800 words.
                      </p>
                      <ol >
                        <li >
                          What do you do or intend to do professionally, why does it matter, and what are your contributions and main achievements to date?
                        </li>
                        <li >
                          Why do you want to be a fellow, what do you hope to gain from your time at MSEC, and what will you bring to the fellowship?
                        </li>
                        <li>
                          How will you invest what you learn at MSEC back into your job, community, and society?
                        </li>
                        <li >
                          What is your vision for making the world a better place?
                        </li>
                        <li >
                          Where do you hope to be in five years’ time?
                        </li>
                      </ol>
                      <div className="form-group mb-4">
                        <label htmlFor="coverLetter">Personal Statement  <span className="text-danger">*</span></label>
                        <textarea
                          rows="20"
                          id="coverLetter"
                          placeholder="Type Statement"
                          className="form-control w-100"
                          value={content || ""}
                          onPaste={handlePaste}
                          onChange={handleInputChange}
                          disabled={isDisabled}

                        ></textarea>
                        <div>
                          Word Count: {countWords(content)}
                          {error && <div className="text-danger">{error}</div>}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="skills">List any relevant technical skills or knowledge related to
                          renewable energy (e.g., coursework, projects, certifications).<span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="skills"
                          placeholder="List any relevant technical skills……"
                          className="form-control w-100"

                          value={skills || ''}
                          onChange={(e) => setSkills(e.target.value)}
                          disabled={isDisabled}

                        ></textarea>
                      </div>

                      <div className="form-group mb-3 col-sm-12 col-md-12">
                        <label htmlFor={`socialMedia`}>How did you hear about this fellowship opportunity? <span className="text-danger">*</span></label>
                        <select
                          id={`socialMedia`}
                          className="form-control"
                          name="socialMedia"
                          value={referalId || ''}
                          onChange={(e) => setReferalId(e.target.value)}
                          disabled={isDisabled}

                        >
                          {/* {referalId === '' ? <option key={0} value={0}>Select option</option> : null} */}
                          <option key={0} value={0}>Select option</option>

                          <option value= "Facebook">Facebook</option>
                          <option value="From a Friend">From a Friend</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Twitter">Twitter</option>
                          <option value="Website">Website</option>
                          <option value="Youtube">Youtube</option>

                        </select>
                      </div>


                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button type="submit" className="btn btn-primary text-white m-1">
                              Save & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default GreenEnergyCommitment;
